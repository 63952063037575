<template scoped>
  <CRUD-CreateUpdate
    ref="crud"
    title="Flow"
    :toPage="toPage"
    :endpoint="endpoint"
    :parameter="parameter"
    apiPath="Backend/Flow"
    :back="back"
    :fields="fields"
  >
  </CRUD-CreateUpdate>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  components: {
    "CRUD-CreateUpdate": () =>
      import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-CreateUpdate.vue"),
  },
  data: function () {
    return {
      fields: [
        {
          name: "manufactoryId",
          label: "Manufactory",
          type: "Suggestion",
          apiPath: "Backend/Manufactory",
          valueName: "manufactoryName",
        },
        {
          name: "productId",
          label: "Product",
          type: "Suggestion",
          apiPath: "Backend/Product",
          valueName: "productName",
        },
        {
          name: "name",
          label: "Name",
          type: "String",
        },
        {
          name: "type",
          label: "Type",
          type: "Options",
          options: [
            { label: "Initial Device", value: "InitialDevice" },
            { label: "Other", value: "Other" },
            {
              label: "Measure Blood Pressure Record",
              value: "MeasureBloodPressureRecord",
            },
            {
              label: "Measure Blood Glucose Record",
              value: "MeasureBloodGlucoseRecord",
            },
            { label: "Measure ECG Record", value: "MeasureECGRecord" },
            { label: "Measure Step Record", value: "MeasureStepRecord" },
            {
              label: "Measure Heart Rate Record",
              value: "MeasureHeartRateRecord",
            },
            {
              label: "Measure Sleep Record",
              value: "MeasureSleepRecord",
            },
            {
              label: "Measure Teeth Record",
              value: "MeasureTeethRecord",
            },
            {
              label: "Measure Temperature Record",
              value: "MeasureTemperatureRecord",
            },
            {
              label: "Sync Blood Pressure Record",
              value: "SyncBloodPressureRecord",
            },
            {
              label: "Sync Blood Glucose Record",
              value: "SyncBloodGlucoseRecord",
            },
            {
              label: "Sync ECG Record",
              value: "SyncECGRecord",
            },
            {
              label: "Sync Step Record",
              value: "SyncStepRecord",
            },
            {
              label: "Sync Heart Rate Record",
              value: "SyncHeartRateRecord",
            },
            {
              label: "Sync Sleep Record",
              value: "SyncSleepRecord",
            },
            {
              label: "Sync Teeth Record",
              value: "SyncTeethRecord",
            },
            {
              label: "Sync Temperature Record",
              value: "SyncTemperatureRecord",
            },
            {
              label: "Monitor Blood Pressure Record",
              value: "MonitorBloodPressureRecord",
            },
            {
              label: "Monitor Blood Glucose Record",
              value: "MonitorBloodGlucoseRecord",
            },
            {
              label: "Monitor ECG Record",
              value: "MonitorECGRecord",
            },
            {
              label: "Monitor Step Record",
              value: "MonitorStepRecord",
            },
            {
              label: "Monitor Heart Rate Record",
              value: "MonitorHeartRateRecord",
            },
            {
              label: "Monitor Sleep Record",
              value: "MonitorSleepRecord",
            },
            {
              label: "Monitor Teeth Record",
              value: "MonitorTeethRecord",
            },
            {
              label: "Monitor Temperature Record",
              value: "MonitorTemperatureRecord",
            },
            {
              label: "Stop Measure Blood Pressure Record",
              value: "StopMeasureBloodPressureRecord",
            },
            {
              label: "Stop Measure Blood Glucose Record",
              value: "StopMeasureBloodGlucoseRecord",
            },
            { label: "Stop Measure ECG Record", value: "StopMeasureECGRecord" },
            { label: "Stop Measure Step Record", value: "StopMeasureStepRecord" },
            {
              label: "Stop Measure Heart Rate Record",
              value: "StopMeasureHeartRateRecord",
            },
            {
              label: "Stop Measure Sleep Record",
              value: "StopMeasureSleepRecord",
            },
            {
              label: "Stop Measure Teeth Record",
              value: "StopMeasureTeethRecord",
            },
            {
              label: "Stop Measure Temperature Record",
              value: "StopMeasureTemperatureRecord",
            },
            {
              label: "Stop Sync Blood Pressure Record",
              value: "StopSyncBloodPressureRecord",
            },
            {
              label: "Stop Sync Blood Glucose Record",
              value: "StopSyncBloodGlucoseRecord",
            },
            {
              label: "Stop Sync ECG Record",
              value: "StopSyncECGRecord",
            },
            {
              label: "Stop Sync Step Record",
              value: "StopSyncStepRecord",
            },
            {
              label: "Stop Sync Heart Rate Record",
              value: "StopSyncHeartRateRecord",
            },
            {
              label: "Stop Sync Sleep Record",
              value: "StopSyncSleepRecord",
            },
            {
              label: "Stop Sync Teeth Record",
              value: "StopSyncTeethRecord",
            },
            {
              label: "Stop Sync Temperature Record",
              value: "StopSyncTemperatureRecord",
            },
            {
              label: "Stop Monitor Blood Pressure Record",
              value: "StopMonitorBloodPressureRecord",
            },
            {
              label: "Stop Monitor Blood Glucose Record",
              value: "StopMonitorBloodGlucoseRecord",
            },
            {
              label: "Stop Monitor ECG Record",
              value: "StopMonitorECGRecord",
            },
            {
              label: "Stop Monitor Step Record",
              value: "StopMonitorStepRecord",
            },
            {
              label: "Stop Monitor Heart Rate Record",
              value: "StopMonitorHeartRateRecord",
            },
            {
              label: "StopMonitor Sleep Record",
              value: "Stop MonitorSleepRecord",
            },
            {
              label: "Stop Monitor Teeth Record",
              value: "StopMonitorTeethRecord",
            },
            {
              label: "Stop Monitor Temperature Record",
              value: "StopMonitorTemperatureRecord",
            }
          ],
        },
      ],
    };
  },
  methods: {
    back: function () {
      var $this = this;
      this.toPage("components/Flow/Portal-Flow.vue", {
        filter: $this.$refs.crud.getFilter(),
      });
    },
  },
};
</script>
